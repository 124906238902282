<template>
  <div class="container-profile">
    <div class="profileBanner"></div>
    <div class="row">
      <div
          class="col-3 mr-auto profile-card profile-user-info d-flex flex-column justify-content-center align-items-center"
      >
        <div class="fs-65 mb-2">
          <font-awesome-icon icon="fa-solid fa-user-circle"/>
        </div>
        <div class="fs-18 mb-2">
          <span class="text-bold uppercase"
          >{{ user?.firstname }} {{ user?.lastname }}</span
          >
        </div>
        <div class="mb-2">
          <span>{{ user?.role?.name }}</span>
        </div>
        <div>
          <span>{{ user?.company?.name }}</span>
        </div>
      </div>

      <div class="col-4 mr-auto d-flex flex-column profile-card">
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Profile</div>

          <div
              v-if="user?.role?.id == '1889B2E8093'"
              class="clickable"
              @click="setEditEmployee"
              style="position: absolute; right: 0; margin-right: 20px"
          >
            <font-awesome-icon icon="fa-solid fa-pencil"/>
          </div>
        </div>

        <div class="row mt-4 mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Role</span>
            <span v-if="user?.role?.name">{{ user?.role?.name }}</span>
            <span v-else>No role assigned</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Role description</span>
            <span v-if="user?.role?.description">{{
                user?.role?.description
              }}</span>
            <span v-else>No role description available</span>
          </div>
        </div>

        <div class="row mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">email</span>
            <span v-if="user?.email">{{ user?.email }}</span>
            <span v-else>No email adress available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">phone</span>
            <span v-if="user?.phone">{{ user?.phone }}</span>
            <span v-else>No phone number available</span>
          </div>
        </div>

        <div class="row mb-auto fs-14">
          <div class="col-12 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">position</span>
            <span v-if="user?.position">{{ user?.position }}</span>
            <span v-else>No position assigned</span>
          </div>
        </div>
      </div>

      <div
          class="col-4 mr-auto d-flex flex-column profile-card"
          v-if="user?.company"
      >
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Company</div>
          <div
              v-if="user?.role?.id == '1889B2E8093'"
              class="clickable"
              @click="setEditCompany"
              style="position: absolute; right: 0; margin-right: 20px"
          >
            <font-awesome-icon icon="fa-solid fa-pencil"/>
          </div>
        </div>

        <div class="row mt-4 mb-4 fs-14">
          <div class="col-12 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Name</span>
            <span v-if="user?.company?.name">{{ user?.company?.name }}</span>
            <span v-else>No company name available</span>
          </div>
        </div>

        <div class="row mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">email</span>
            <span v-if="user?.company?.email">{{ user?.company?.email }}</span>
            <span v-else>No company email adress available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">phone</span>
            <span v-if="user?.company?.phone">{{ user?.company?.phone }}</span>
            <span v-else>No company phone number available</span>
          </div>
        </div>

        <div class="row mb-auto fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">address</span>
            <span v-if="user?.company?.address">{{
                user?.company?.address
              }}</span>
            <span v-else>No company address available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">website</span>
            <a v-if="user?.company?.website" :href="user?.company?.website">{{
                user?.company?.website
              }}</a>
            <span v-else>No company website available</span>
          </div>
        </div>
      </div>

      <div class="col-4 mr-auto d-flex flex-column profile-card" v-else>
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">No company assigned</div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3 p-3 mr-auto d-flex flex-column logo-card" v-if="user?.role?.id === '18EEC46A8DB'">
        <div class="pb-2 uppercase fs-14 text-grey">Logo</div>
        <div
            class="dropzone border border-primary rounded p-4 text-center"
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="dropFile"
            @click="openFilePicker"
            :class="{ 'bg-light': isDragging }"
        >
          <scoped-loading-overlay v-if="loading"></scoped-loading-overlay>
          <template v-if="!file && !fileUrl">
            <p>Drag & Drop a logo here or click to upload</p>
          </template>
          <template v-else>
            <img :src="fileUrl" alt="Uploaded Image" class="img-fluid rounded mb-2 mt-3"/>
            <p class="fs-14 text-bold">{{ fileName }}</p>
          </template>
          <input type="file" class="d-none" ref="fileInput" @change="onFileSelect"/>
        </div>

        <div class="text-center mt-2 d-flex">
          <button v-if="!file" class="btn btn-sm btn-primary" @click="openFilePicker">Select Logo</button>
          <button v-else class="btn btn-sm btn-success" @click="submitFile">Submit Logo</button>
          <button
              class="btn btn-sm btn-danger-light ml-auto"
              :disabled="fileUrl === null"
              @click="deleteFile"
          >
            <font-awesome-icon icon="fa-solid fa-trash"/>
            <span class="ml-2">Remove Logo</span>
          </button>
        </div>
      </div>
    </div>

    <company-modal
        id="company-modal"
        @addCompany="addCompany"
        :editCompany="editCompany"
    />

    <employee-modal
        id="employee-modal"
        @addEmployee="addEmployee"
        :editEmployee="editEmployee"
    />
  </div>
</template>

<script>
import store from "@/store";
import Api from "@/Api";

import CompanyModal from "@/components/Modals/CompanyModal";
import EmployeeModal from "@/components/Modals/EmployeeModal";
import ScopedLoadingOverlay from "@/components/global/ScopedLoadingOverlay.vue";

export default {
  name: "Profil",
  components: {
    ScopedLoadingOverlay,
    CompanyModal,
    EmployeeModal,
  },
  mixins: [],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "";
      },
    };
  },
  data() {
    return {
      editEmployee: null,
      editCompany: null,
      isDragging: false,
      file: null,
      fileUrl: null,
      fileName: null,
      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        if (this.$store.state.user) return this.$store.state.user;
        else {
          return {
            user: {
              id: null,
              firstname: null,
              lastname: null,
              company: {
                id: null,
                name: null,
                address: null,
                phone: null,
                email: null,
                website: null,
              },
              role: {name: null, description: null},
              position: null,
              email: null,
              phone: null,
            },
          };
        }
      },
    },
  },
  created() {
    this.fetchExistingLogo();
  },
  mounted() {
  },
  methods: {
    setEditCompany() {
      this.editCompany = this.user.company;
      this.$bus.$emit("open-modal", "company-modal");
    },
    addCompany(company) {
      if (company) {
        if (company.id) {
          Api.put("company/" + company.id, company)
              .then((response) => {
                this.$notify({
                  type: "success",
                  title: "Action Successful",
                  text: "Company successfully updated.",
                  duration: 8000,
                });
                this.user.company = response.data;
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
              });
        }
      }
    },
    setEditEmployee() {
      this.editEmployee = this.user;
      this.$bus.$emit("open-modal", "employee-modal");
    },
    addEmployee(employee) {
      if (employee) {
        if (employee.id) {
          Api.put("/employee/" + employee.id, employee)
              .then((response) => {
                this.$notify({
                  type: "success",
                  title: "Action Successful",
                  text: "Employee successfully edited.",
                  duration: 8000,
                });
                this.$store.commit("setUser", response.data);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
              });
        }
      }
    },
    async fetchExistingLogo() {
      try {
        this.loading = true;
        const response = await Api.get("/logo");
        if (response.data && response.data.path) {
          this.resetFileValues()
          this.fileUrl = process.env.VUE_APP_SERVER_URL + response.data.path + "?force=reload";
          this.fileName = response.data.name;
          this.$store.commit('setLogoUrl', this.fileUrl);
        }
      } catch (error) {
        console.error("Error fetching existing logo", error);
      } finally {
        this.loading = false
      }
    },
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    dropFile(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.setFile(files[0]);
      }
    },
    onFileSelect(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.setFile(files[0]);
      }
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    setFile(file) {
      this.file = file;
      this.fileUrl = URL.createObjectURL(file);
      this.fileName = file.name
    },
    async submitFile() {
      if (!this.file) return;

      this.loading = true;
      const formData = new FormData();
      formData.append("logo", this.file);

      try {
        const response = await Api.post("/logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.resetFileValues()
        this.fileUrl = this.fileUrl = process.env.VUE_APP_SERVER_URL + response.data.path + "?force=reload";
        this.fileName = response.data.name;
        this.$store.commit('setLogoUrl', this.fileUrl);
      } catch (error) {
        console.error("Error uploading file", error);
      } finally {
        this.loading = false
      }
    }, async deleteFile() {
      if (!this.fileUrl) return;
      try {
        this.loading = true;
        await Api.delete("/logo",);
        this.resetFileValues()
        this.$store.commit('setLogoUrl', "https://carogusto-live.batix.ch/pic/18B89CD8B48.png");
      } catch (error) {
        console.error("Error uploading file", error);
      } finally {
        this.loading = false
      }
    },
    resetFileValues() {
      this.file = null;
      this.fileUrl = null;
      this.fileName = null;
    }
  },
}
;
</script>

<style lang="scss">
</style>
	